export default {
  columns: [
    {
      value: '0',
      text: 'ui_bets_search_options[0]'
    },
    {
      value: '1',
      text: 'ui_bets_search_options[1]'
    },
    {
      value: '2',
      text: 'ui_bets_search_options[2]'
    },
    {
      value: '3',
      text: 'ui_bets_search_options[3]'
    }
  ],
  table: {
    title: [
      {
        name: 'ui_text_048'
      },
      {
        name: 'ui_bet_time_colon'
      },
      {
        name: 'rp_game'
      },
      {
        name: 'rp_bet'
      },
      {
        name: 'ui_text_016'
      }
    ],
    keys: ['betNo', 'betTime', 'gameName', 'betAmount', 'winLose']
  },
  detail: {
    keys: ['betNo', 'betTime', 'gameName', 'betAmount', 'winLose'],
    title: {
      betNo: 'ui_text_048',
      betTime: 'rp_play',
      gameName: 'rp_number',
      betAmount: 'ui_bet_time_colon',
      winLose: 'rp_game'
    }
  }
};
